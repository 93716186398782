import { type GlobalContext, Http } from '@sgmo/shared';

import { rapidToast } from '@/helpers/powerFullToast';

import type { Permissions } from '@/types/permissions';

import { getCurrentContext } from '@/context/UserContext';

const preLoad = rapidToast('Settlement');

export const PresentationService = {
  getPermissions: (abortSignal?: AbortSignal): Promise<Permissions> => {
    return Http.post(
      'permissions',
      [],
      {
        accessNames: ['ConfirmationDocumentTypes', 'Commands', 'CanWriteSmartDXDoc'],
      },
      abortSignal ?? new AbortController().signal,
      // TODO ABO, manage global context
      getCurrentContext() as GlobalContext,
    ).then((response) => {
      const toast = preLoad('PresentationService');

      if (!response.ok) {
        toast(response.status);
        throw new Error(`API responded with error status ${response.status}`);
      }

      return response.json();
    });
  },
};
