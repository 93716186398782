import type { LoaderFunctionArgs, Params, RouteObject } from 'react-router';
import { type ChasingInfo, isUrlChasingInfos } from '@/types/fileInfos';
import { PresentationService } from '@/services/PresentationService';
import {
  getMergedPermssions,
  getRightsFromPayload,
  getUtilsPermissions,
} from '@/components/routes/Editor/common/Rights';
import type { FileInfo } from '@/store/document.slice';
import { Layout } from '@/components/layout/Layout';
import { LandingPage } from '@/components/routes/Landing';
import { setCurrentConfig } from '@/store/editor.slice';
import { useSelector } from 'react-redux';
import { type Rights, selectRights, setOrigin, setRights } from '@/store/ui.slice';
import { PageNotFound } from '@/components/routes/PageNotFound';
import { store } from '@/store/store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SmartCommsTypes } from '@/types/SmartCommsApi';
import { isDefined } from '@sgme/fp';

function setOriginState(origin: 'xone' | 'octane' | 'kb' = 'xone') {
  store.dispatch(
    setOrigin({
      isXone: origin === 'xone',
      isOctane: origin === 'octane',
      isKB: origin === 'kb',
    }),
  );
}

export function getRoutes(): RouteObject[] {
  const dispatch = (action: PayloadAction<Rights | SmartCommsTypes['EditConfig']>) => store.dispatch(action);

  return [
    {
      path: '/',
      element: <Layout />,
      loader: parametersLoader,
      children: [
        {
          index: true,
          element: <LandingPage />,
        },
        // Description: takes the user to a page to open a document with edit rights (as opposed to the one below)
        {
          path: 'review',
          lazy: async () => {
            setOriginState();
            const { ReviewPage } = await import('./Editor/common/ReviewPage');

            return { Component: ReviewPage };
          },
        },
        // Description: takes the user to a page to open a document but with no edit rights (as opposed to the one above)
        {
          path: 'preview',
          lazy: async () => {
            setOriginState();
            const { PreviewXone } = await import('./Editor/Xone/PreviewXone/PreviewXone');

            return { Component: PreviewXone };
          },
        },
        // Description: review page for users coming from X-One, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'xone/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState();
            const { ReviewPage } = await import('./Editor/common/ReviewPage');

            return { Component: ReviewPage };
          },
        },
        // Description: preview page for users coming from X-One, does not allow users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'Preview/xone/:docType/:tradeRef/:docId',
          lazy: async () => {
            setOriginState();
            const { PreviewXone } = await import('./Editor/Xone/PreviewXone/PreviewXone');

            return { Component: PreviewXone };
          },
        },
        // Description: review page for users coming from Enterprise, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'ent/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState();
            const { ReviewPage } = await import('./Editor/common/ReviewPage');

            return { Component: ReviewPage };
          },
        },
        // Description: review page for users coming from Octane, allows users to edit a document
        //   Params:
        //     :codent: enterprise code (i.e. SG)
        //     :codtrs: document id (i.e. 0123456789)
        //     :chasing: chasing type (i.e. Reminder, Out_of_Target...)
        //     :chasingDate: chasing date (i.e. 21_02_24_12_00_00_AM)
        //     :client: client to be chased (i.e. TOTAL)
        {
          path: 'octane/CHASING/:codent/:codtrs/:chasing/:chasingDate/:client',
          lazy: async () => {
            setOriginState('octane');
            const { ReviewPage } = await import('./Editor/common/ReviewPage');

            return { Component: ReviewPage };
          },
        },
        // Description: review page for users coming from Octane, allows users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :assetClass: asset class (i.e. IRD, ENU...)
        //     :docId: document id (i.e. 0123456789)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        {
          path: 'octane/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState('octane');
            const { ReviewPage } = await import('./Editor/common/ReviewPage');

            return { Component: ReviewPage };
          },
        },
        // Description: preview page for documents from SG Docs
        //   Params:
        //     :sgDocsId: document id (i.e. 0123456789)
        {
          path: 'Preview/xone/Sgdoc/:sgDocsId',
          lazy: async () => {
            dispatch(setCurrentConfig({ mode: 'readOnly' }));
            setOriginState();
            const { SgDocPreview } = await import('./Editor/SgDocs/SgDocPreview/SgDocPreview');

            return { Component: SgDocPreview };
          },
        },
        // Description: dedicated page to compare two SG Docs documents
        //   Params:
        //     :sgDocsId: document id (i.e. 0123456789)*/}
        //     :tradeRef: trade reference (i.e. ENU-1234567)*/}
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        {
          path: 'showDiff/:sgDocsId/:tradeRef',
          lazy: async () => {
            const { SgDocsComparator } = await import('./Editor/SgDocs/SgDocsComparator');

            return { Component: SgDocsComparator };
          },
        },
        // Description: dedicated page for KB users to edit a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'kb/:docType/:assetClass/:docId/:tradeRef',
          lazy: async () => {
            setOriginState('kb');
            const { ReviewPage } = await import('./Editor/common/ReviewPage');

            return { Component: ReviewPage };
          },
        },
        // Description: dedicated page for KB users to preview a document
        //   Params:
        //     :docType: document type (i.e. Confirmation, TermSheet...)
        //     :tradeRef: trade reference (i.e. ENU-1234567)
        //     :docId: document id (i.e. 0123456789)
        {
          path: 'Preview/kb/:docType/:tradeRef/:docId',
          lazy: async () => {
            setOriginState('kb');

            const { canRead, canWrite, canUseSmartDX } = useSelector(selectRights);
            if (canWrite) {
              dispatch(setRights({ canRead, canWrite: false, canUseSmartDX }));
              dispatch(setCurrentConfig({ mode: 'readOnly' }));
            }

            const { PreviewXone } = await import('./Editor/Xone/PreviewXone/PreviewXone');

            return { Component: PreviewXone };
          },
        },
      ],
      errorElement: <PageNotFound />,
    },
  ];
}

async function parametersLoader({ request, params }: LoaderFunctionArgs)  {
  // GET FILE PARAMS
  const isChasing = isUrlChasingInfos(params);
  const fileParameters = getFileParams(params, isChasing);

  // GET QUERY PARAMS
  const queryParameters = getQueryParameters(request.url);

  // GET PERMISSIONS
  const { permissions } = await PresentationService.getPermissions(new AbortController().signal);

  // GET RIGHTS
  const utilsPermissions = getUtilsPermissions(permissions);
  const mergedPermissions = getMergedPermssions(utilsPermissions);

  const rightsFromPayload = getRightsFromPayload(
    mergedPermissions,
    fileParameters.documentType ?? '',
    'CanWriteSmartDXDoc',
  );

  const rightsList = {
    canRead: rightsFromPayload[0],
    canWrite: queryParameters.noFlag ? !queryParameters.isreadonly : rightsFromPayload[1],
    canUseSmartDX: rightsFromPayload[2],
  };
  // sgwtWebAnalytics ???

  return { fileParameters, queryParameters, rightsList };
};

function getFileParams(params: Params<string>, isChasing: boolean): FileInfo | ChasingInfo {
  if (isChasing) {
    const { codent, codtrs, chasing, chasingDate, client } = params;

    return {
      documentType: 'CHASING',
      codent,
      codtrs: Number.parseInt(codtrs ?? '', 10),
      chasing,
      chasingDate,
      client: Number.parseInt(client ?? '', 10),
    };
  }

  const { docId, sgDocsId, docType: documentType, tradeRef: tradeReference, assetClass, isReadOnly } = params;
  const documentId = sgDocsId ?? docId;
  const isSGDoc = isDefined(sgDocsId);

  return {
    documentId,
    documentType: isSGDoc ? 'Confirmation' : documentType ?? '',
    tradeReference: tradeReference ?? '',
    assetClass: assetClass ?? '',
    isReadOnly: isSGDoc || isReadOnly === 'true',
  };
}

export function isChasingFileInfos(object: FileInfo | ChasingInfo): object is ChasingInfo {
  return 'codent' in object;
}

function getQueryParameters(requestUrl: string) {
  const searchParameters = new URL(requestUrl).searchParams;

  const { isredraft, isoriginal, isbomanager, SkipReviewCase, isreadonly } = {
    isredraft: searchParameters.get('isRedraft') ?? searchParameters.get('isredraft'),
    isoriginal: searchParameters.get('isOriginal') ?? searchParameters.get('isoriginal'),
    isbomanager: searchParameters.get('isBoManager') ?? searchParameters.get('isbomanager'),
    SkipReviewCase: searchParameters.get('SkipReviewCase') ?? searchParameters.get('skipreviewcase'),
    isreadonly: searchParameters.get('isreadonly'),
  };

  return {
    isredraft: isredraft === null ? false : isredraft !== 'False',
    isoriginal: isoriginal === null ? true : isoriginal === 'True',
    isbomanager: isbomanager === null ? false : isbomanager !== 'False',
    SkipReviewCase: SkipReviewCase === null ? true : SkipReviewCase === 'True',
    isreadonly: isreadonly === null ? false : isreadonly === 'True',
    noFlag: isredraft === null && isoriginal === null && isbomanager === null && SkipReviewCase === null,
  };
}
