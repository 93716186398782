import './App.css';
import type { SGWTConnectCore } from '@sgwt/connect-core';

import { IntlProvider } from '@/context/IntlContext';
import { createBrowserRouter, RouterProvider } from 'react-router';

import { setCurrentContext, UserContext } from '@/context/UserContext';

import type { GlobalContext } from '@/types/global-context';
import { getConfig } from '@/config/config';
import { ToasterProvider } from '@/components/common/ToasterProvider';
import { getRoutes } from '@/components/routes/routes';

export function App({ sgConnect }: { sgConnect: SGWTConnectCore }) {
  const globalContext: GlobalContext = {
    clientScopeCode: 'SCP0',
    setClientScope: () => undefined,
    permissions: null,
    sgConnect,
    user: null,
    appConfig: getConfig(),
  };

  setCurrentContext(globalContext);

  const routes = getRoutes();
  const router = createBrowserRouter(routes, { basename: getConfig().publicBaseUrl });

  return (
    <div id="main" className="vh-100">
      <IntlProvider>
        <UserContext.Provider value={{ globalContext }}>
          <ToasterProvider>
            <RouterProvider
              router={router}
            />
          </ToasterProvider>
        </UserContext.Provider>
      </IntlProvider>
    </div>
  );
}
