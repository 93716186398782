import '@/components/layout/layout.css';

import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';
import { Outlet, useLoaderData } from 'react-router';
import { useDispatch } from 'react-redux';
import { type Rights, setQuery, setRights } from '@/store/ui.slice';
import { type FileInfo, setChasing, setFile } from '@/store/document.slice';
import type { ChasingInfo } from '@/types/fileInfos';
import { DocumentProvider } from '@/context/DocumentContext';
import { isChasingFileInfos } from '@/components/routes/routes';
import { Suspense } from 'react';
import { Sgb4Spinner } from '@/components/common/Sgb4Spinner';

type QueryParameters = {
  isredraft: boolean;
  isoriginal: boolean;
  isbomanager: boolean;
  SkipReviewCase: boolean;
  isreadonly: boolean;
  noFlag: boolean;
};

type LoaderData = {
  fileParameters: FileInfo | ChasingInfo;
  queryParameters: QueryParameters;
  rightsList: Rights;
};

export function Layout() {
  const dispatch = useDispatch();

  // TODO ABO, refine loader data type
  const { fileParameters, queryParameters, rightsList } = useLoaderData() as LoaderData;

  if (isChasingFileInfos(fileParameters)) {
    dispatch(setChasing(fileParameters));
  } else {
    dispatch(setFile(fileParameters));
  }
  dispatch(setQuery(queryParameters));
  dispatch(setRights(rightsList));

  return (
    <Suspense
      fallback={
        <div className="m-5 d-flex justify-content-center">
          <Sgb4Spinner />
        </div>
      }
    >
      <div className="layout">
        <Header />

        <DocumentProvider>
          <Outlet />
        </DocumentProvider>

        <Footer />
      </div>
    </Suspense>
  );
}
