import { createContext, type PropsWithChildren, useState } from 'react';

export type DocumentContent = string | null;

type DocumentContextType = {
  document: DocumentContent;
  setDocument: (document: string | null) => void;
};

export const DocumentContext = createContext<DocumentContextType>({ document: null, setDocument: () => {} });

export function DocumentProvider({ children }: PropsWithChildren) {
  const [document, setDocument] = useState<DocumentContent>(null);

  return <DocumentContext.Provider value={{ document, setDocument }}>{children}</DocumentContext.Provider>;
}
