import type { Permission } from '@/types/permissions';

export interface Rights {
  canRead: string[];
  cannotRead: string[];
  canWrite: string[];
  cannotWrite: string[];
  name: string[];
}

const getReadFromPayload = (mergedPerms: Rights, documentType: string) => {
  if (mergedPerms.cannotRead.includes(documentType)) {
    return false;
  }

  if (mergedPerms.canRead.includes(documentType)) {
    return true;
  }

  if (mergedPerms.cannotRead.includes('ANY')) {
    return false;
  }

  return mergedPerms.canRead.includes('ANY');
};

const getWriteFromPayload = (mergedPerms: Rights, documentType: string) => {
  if (mergedPerms.cannotWrite.includes(documentType)) {
    return false;
  }

  if (mergedPerms.canWrite.includes(documentType)) {
    return true;
  }

  if (mergedPerms.cannotWrite.includes('ANY')) {
    return false;
  }

  return mergedPerms.canWrite.includes('ANY');
};

const getCanUseSmartDXFromPayload = (mergedPerms: Rights, useSmartDX: string) => mergedPerms.name.includes(useSmartDX);

export const getRightsFromPayload = (
  mergedPerms: Rights,
  documentType: string,
  useSmartDX: string,
): [boolean, boolean, boolean] => [
  getReadFromPayload(mergedPerms, documentType),
  getWriteFromPayload(mergedPerms, documentType),
  getCanUseSmartDXFromPayload(mergedPerms, useSmartDX),
];

export function getUtilsPermissions(permissions: Permission[]): Permission[] {
  const initialPermissionsList: Permission[] = Array.from({ length: 3 });

  return permissions.reduce((permissionsList, permission) => {
    let indexStart = null;

    switch (permission.name) {
      case 'Commands':
        indexStart = 0;
        break;
      case 'ConfirmationDocumentTypes':
        indexStart = 1;
        break;
      case 'CanWriteSmartDXDoc':
        indexStart = 2;
    }

    if (indexStart !== null) {
      permissionsList.splice(indexStart, 1, permission);
    }

    return permissionsList;
  }, initialPermissionsList);
}

export function getMergedPermssions(permissions: Permission[]) {
  const [canReadPermissions, canWritePermissions, CanWriteSmartDXDocPermissions] =
    permissions;

  const canRead = [
    ...canReadPermissions.canRead,
    ...canWritePermissions.canRead,
  ].filter((permission) => permission !== '');

  const canWrite = [
    ...canReadPermissions.canWrite,
    ...canWritePermissions.canWrite,
  ].filter((permission) => permission !== '');

  const cannotRead = [
    ...canReadPermissions.cannotRead,
    ...canWritePermissions.cannotRead,
  ].filter((permission) => permission !== '');

  const cannotWrite = [
    ...canReadPermissions.cannotWrite,
    ...canWritePermissions.cannotWrite,
  ].filter((permission) => permission !== '');

  return {
    canRead,
    canWrite,
    cannotRead,
    cannotWrite,
    name: [
      canReadPermissions.name,
      canWritePermissions.name,
      CanWriteSmartDXDocPermissions?.name ?? '',
    ],
  };
}
