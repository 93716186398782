import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { ToasterContext } from '@/context/ToasterContext';
import { UserContext } from '@/context/UserContext';

import type { Perms } from '@/helpers/SmartDXWrap';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

const Card = styled.button.attrs<{ rights: Perms }>({ type: 'button', className: 'col mb-3' })`
  cursor: ${(props) => (props.rights[1] ? 'pointer' : undefined)};
  border-width: 0;
  border-color: transparent;
  border: none;
  background-color: transparent;
  text-align: justify;
  &:focus {
    border-width: 0;
    border-color: transparent;
    border: none;
    outline: none;
  }
  &:focus-visible {
    border-width: 0;
    border-color: transparent;
    border: none;
    outline: none;
  }
`;

export const LandingPage = () => {
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const context = useContext(UserContext).globalContext;
  const [rights, setRights] = useState<Perms>([true, true, true]);

  const navigation = useNavigate();

  const pushToast = useContext(ToasterContext).pushToastMessage;

  if (context?.appConfig?.sgwt_env === 'homologation') {
    (global as unknown as { setRights: unknown }).setRights = setRights;
    (global as unknown as { pushToast: unknown }).pushToast = pushToast;
  }

  if (!rights[0]) {
    window.location.replace(`https://shared.sgmarkets.com/unauthorized/?redirectUri=${window.location}`);
  }

  const navigateTo = (page: string) => {
    sgwtWebAnalytics?.trackEvent('Navigation', `Navigating to ${page}`);
    navigation(page);
  };

  return (
    <div className="container-fluid h-100">
      <div className="container px-md-5 pt-4 pt-md-5">
        <div className="d-flex row row-cols-1 row-cols-lg-2 mb-2 mb-xl-3 ml-1">
          <h1 className="display-3">
            <FormattedMessage id="landing.title" />
          </h1>
        </div>

        <div className="d-flex justify-content-center row row-cols-1 row-cols-lg-2">
          <Card rights={rights} onClick={() => navigateTo('/review')}>
            <div
              className={`card ${rights[1] ? 'card-filling' : ''}`}
              style={{
                backgroundColor: rights[1] ? undefined : 'var(--bg-lvl1)',
                opacity: rights[1] ? undefined : '0.3',
              }}
            >
              <div className="card-header bg-secondary-alpha-md p-4" style={{ minHeight: '128px' }}>
                <h3 className="mb-1" style={{ cursor: rights[1] ? 'pointer' : 'default' }}>
                  <FormattedMessage id="landing.review" />
                </h3>
                <span className="text-secondary" style={{ cursor: rights[1] ? 'pointer' : 'default' }}>
                  <FormattedMessage id="landing.review_desc" />
                </span>
              </div>
              <div className="card-body bg-secondary-alpha-md py-5 px-4" style={{ minHeight: '128px' }} />
              <div className="card-footer bg-secondary-alpha-md p-4">
                <div
                  className="rounded-circle bg-secondary-alpha-md d-flex align-items-center justify-content-center mt-lg-5"
                  style={{ width: '5rem', height: '5rem' }}
                >
                  <em className="icon icon-xl text-secondary line-height-1">task</em>
                </div>
              </div>
            </div>
          </Card>

          <Card rights={rights} onClick={() => navigateTo('/preview')}>
            <div className="card card-filling">
              <div className="card-header bg-secondary-alpha-md p-4" style={{ minHeight: '128px' }}>
                <h3 className="mb-1" style={{ cursor: 'pointer' }}>
                  <FormattedMessage id="landing.preview" />
                </h3>
                <span className="text-secondary" style={{ cursor: 'pointer' }}>
                  <FormattedMessage id="landing.preview_desc" />
                </span>
              </div>
              <div className="card-body bg-secondary-alpha-md py-lg-5 px-4" style={{ minHeight: '128px' }} />
              <div className="card-footer bg-secondary-alpha-md p-4">
                <div
                  className="rounded-circle bg-secondary-alpha-md d-flex align-items-center justify-content-center mt-lg-5"
                  style={{ width: '5rem', height: '5rem' }}
                >
                  <em className="icon icon-xl text-secondary line-height-1">visibility</em>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
